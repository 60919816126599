import AmazonSCImage from '../assets/amazon_small.png';
import AmazonVCImage from '../assets/amazon_small.png';
import FaireRoundImage from '../assets/faire_round.png';
import KeHERoundImage from '../assets/kehe_round.jpeg';
import CostcoRoundImage from '../assets/costco_round.jpg';
import SPINSRoundImage from '../assets/spins_round.png';
import KrogerRoundImage from '../assets/kroger_round.png';
import TargetRoundImage from '../assets/target_round.png';
import UNFIRoundImage from '../assets/unfi_round.png';
import WalmartRoundImage from '../assets/walmart_round.png';
import WegmansRoundImage from '../assets/wegmans_round.png';
import WholeFoodsRoundImage from "../assets/whole_foods.png";
import VIPLogoRound from "../assets/vip_onboarding/vip-logo-round.png";
import ShopifyLogoRound from "../assets/shopify_round.svg";
import ThriveRoundLogo from '../assets/thrive_round.jpg';
import DorasRoundLogo from '../assets/doras.png';
import BJsWholeSaleClubLogo from '../assets/bjs_whole_sale_club.png';

// NOTE: to hid integrations in development use hidden: true
// TODO: consider deprecating hidden flag
const integrationsMeta = [
  {
    name: 'Amazon Seller Central',
    title: 'Orders, item sales and other reporting.',
    imageUrl: AmazonSCImage,
    integration_id: 11,
    component: 'AmazonSellerCentral',
    collectionUrl: '/collection/357-amazon-seller-central'
  },
  {
    name: 'Amazon Vendor Central',
    title: 'Daily sales rollups, POs, and forecasts.',
    imageUrl: AmazonVCImage,
    integration_id: 17,
    component: 'AmazonVendorCentral'
  },
  {
    name: 'Costco',
    title: 'Daily sales and inventory.',
    imageUrl: CostcoRoundImage,
    integration_id: 15,
    component: 'CostcoSmallVendor',
    collectionUrl: '/collection/570-costco'
  },
  {
    name: 'Faire',
    title: 'Orders, items, and daily rollups.',
    imageUrl: FaireRoundImage,
    integration_id: 8,
    component: 'Faire',
    collectionUrl: '/collection/548-faire'
  },
  {
    name: 'KeHE',
    title: 'Daily DC inventory, weekly DC shipments.',
    imageUrl: KeHERoundImage,
    integration_id: 2,
    component: 'Kehe',
    collectionUrl: '/collection/377-kehe'
  },
  {
    name: 'Kroger',
    title: 'Daily store sales and inventory.',
    imageUrl: KrogerRoundImage,
    integration_id: 7,
    component: 'Kroger',
    collectionUrl: '/collection/510-kroger'
  },
  {
    name: 'SPINS Store Insights',
    title: 'Store-level sales via Store Insights.',
    imageUrl: SPINSRoundImage,
    integration_id: 19,
    component: 'SPINSStoreInsights',
  },
  {
    name: 'Target',
    title: 'Store sales, DC and store inventory.',
    imageUrl: TargetRoundImage,
    integration_id: 5,
    component: 'Target',
    collectionUrl: '/collection/412-target'
  },
  {
    name: 'Walmart',
    title: 'Sales, Inventory, Purchase Orders',
    imageUrl: WalmartRoundImage,
    integration_id: 6,
    component: 'Walmart',
    collectionUrl: '/collection/413-walmart'
  },
  {
    name: 'Wegmans',
    title: 'Daily store sales, your brand and others.',
    imageUrl: WegmansRoundImage,
    integration_id: 1,
    component: 'Wegmans',
    collectionUrl: '/collection/509-wegmans'
  },
  {
    name: "BJ's Wholesale Club",
    title: "Sales data from BJ's",
    integration_id: 22,
    imageUrl: BJsWholeSaleClubLogo,
    component: 'BJs',
  },
  {
    name: "Whole Foods",
    title: "Sales data and product data.",
    imageUrl: WholeFoodsRoundImage,
    integration_id: 23,
    component: 'WholeFoodsSRP',
    collectionUrl: '/collection/378-whole-foods'
  },
  {
    name: "myUNFI",
    title: "Sales and inventory data.",
    integration_id: 25,
    imageUrl: UNFIRoundImage,
    component: 'MyUNFI',
    collectionUrl: '/collection/511-unfi'
  },
  {
    name: "VIP",
    title: "Sales data from VIP",
    integration_id: 26,
    imageUrl: VIPLogoRound,
    component: 'VIP',
    collectionUrl: "/collection/344-vip"
  },
  {
    name: "Shopify",
    title: "Sales data from Shopify",
    integration_id: 27,
    imageUrl: ShopifyLogoRound,
    component: 'Shopify',
  },
  {
    name: "Thrive",
    title: "Sales data from Thrive",
    integration_id: 20,
    imageUrl: ThriveRoundLogo,
    component: 'Thrive',
  },
  {
    name: "Dora's Naturals",
    title: "Sales data from Dora's",
    integration_id: 30,
    imageUrl: DorasRoundLogo,
    component: 'Doras',
  },
];

const integrationIds = integrationsMeta.map(i => i.integration_id);

export default integrationsMeta;

export { integrationIds };
