import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUserContext } from './UserContext.jsx';
import { supabase } from "../libs/api";
const OrganizationContext = createContext();

export const useOrganizationContext = () => useContext(OrganizationContext);

export const OrganizationProvider = ({ children }) => {
  const { currentOrganization } = useUserContext('');
  const [organization, setOrganization] = useState({});


  const getOrganization = async () => {
    if (currentOrganization) {
      const { data, error } = await supabase
        .rpc('get_enriched_organization_v3', { _organization_id: currentOrganization });
      if (error) {
        console.error('Error fetching data:', error);
        return;
      }
      const orgData = data;
      setOrganization(orgData);
    }
  }

  const getHgJwt = async() => {
    if (currentOrganization){
      const { data, error } = await supabase
      .functions.invoke('generate_hg_jwt');
      return data;
    }
  }

  const getMbJwt = async() => {
    if (currentOrganization){
      const { data, error } = await supabase
        .rpc('get_organization_mb_jwt_v1', { _organization_id: currentOrganization });
      return data;
    }
  }

  useEffect(() => {
    getOrganization();
  }, [currentOrganization]);


  return (
    <OrganizationContext.Provider value={{ organization, setOrganization, getHgJwt, getMbJwt}}>
      {children}
    </OrganizationContext.Provider>
  );
};
