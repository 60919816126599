import { supabase } from "../libs/api";
import integrationsMeta from '../data/integrations.js';

const getFileUploadHistory = async(cdsId) => {
  const { data, error } = await supabase.schema('frontend')
    .rpc('get_customer_file_uploads', {'_connection_data_source_id': cdsId });

  if (error) {
    console.error('Error getting customer file history:', error);
  }

  return { data, error};
};

const getPreSignedS3Url = async(cdsId, fileName) => {
  const { data, error } = await supabase.schema('frontend')
    .rpc('get_next_upload_filepath', {'_connection_data_source_id': cdsId, '_file_name': fileName});

  if (error) {
    console.error('Error getting pre-signed url:', error);
  }

  return { data, error};
};

const getAvailableEmbeds = async(orgId) => {
  const { data, error } = await supabase.schema('frontend')
    .rpc('get_available_embeds', {_organization_id: orgId});

  if (error) {
    console.error('Error getting available embeds:', error);
    return;
  }

  return data;
};

const getSubscription = async() => {
  const { data, error } = await supabase.schema('frontend')
    .rpc('get_subscription');

  if (error) {
    console.error('Error getting subscription:', error);
    return;
  }

  return data;
};

const updateDefaultOrg = async(orgId) => {
  const { data, error } = await supabase.schema('frontend')
    .rpc('update_default_org_id', { _org_id: orgId });

  if (error) {
    console.error('Error updating default org id:', error);
    return;
  }

  return data;
};

const showUserTOS = async() => {
  const { data, error } = await supabase.schema('frontend')
    .rpc('show_user_tos');

  if (error) {
    console.error('Error updating tos accept:', error);
    return;
  }

  return data;
};

const acceptTOSQuery = async() => {
  const { data, error } = await supabase.schema('frontend')
    .rpc('accept_tos');

  if (error) {
    console.error('Error updating tos accept:', error);
    return;
  }

  return data;
}

const getOrganizationConsumerProducts = async(organization_id) => {
  const { data, error } = await supabase
    .rpc('get_organization_consumer_products_v1', { _organization_id: organization_id });

  if (error) {
    console.error('Error getting org products info:', error);
    return;
  }

  return data;
}

const getOrganizationProducts = async(organization_id, integration_id) => {
  const { data, error } = await supabase
    .rpc('get_organization_products_v1', { _organization_id: organization_id, _integration_id: integration_id});

  if (error) {
    console.error('Error getting org products info:', error);
    return;
  }

  return data;
}

const getConnectionInfo = async(connection_id) => {
  const { data, error } = await supabase
    .rpc('get_connection_external_model_summary_v1', { _connection_id: connection_id});

  if (error) {
    console.error('Error getting connection info:', error);
    return;
  }

  return data;
}

const justFetchSteps = async(connection_id) => {
  const { data, error } = await supabase.schema('frontend')
  .rpc('get_connection_onboarding_steps', { _connection_id: connection_id });

  if (error) {
    console.error('Error fetching steps:', error);
    return;
  }
  return data;
}

const fetchSteps = async(connection_id, stepsFrontendData) => {
  let { data: stepData, error } = await supabase.schema('frontend')
  .rpc('get_connection_onboarding_steps', { _connection_id: connection_id });

  if (error) {
    console.error('Error fetching steps:', error);
    return;
  }

  if (!stepData) {
    return null;
  }

  const firstStep = stepData.find(s => s.step_id === 0);
  if (firstStep && firstStep.status === 'upcoming') {
    const { error: updateStepError } = await supabase
      .from('connection_onboarding_steps')
      .update({ status: 'current' })
      .eq('id', firstStep.id);

    if (updateStepError) {
      console.error("Update Step Error: ", updateStepError);
    } else {
      stepData = stepData.map(step =>
        step.id === firstStep.id ? { ...step, status: 'current' } : step
      );
    }
  }

  const enrichedData = stepData.map(st => {
    const matchingStep = stepsFrontendData.find(s => s.step_id === st.step_id);
    return {
      ...st,
      ...(matchingStep || {}),
    };
  });
  return enrichedData
};

const fetchCOSI = async(firstStep) => {
  const { data, error } = await supabase.schema('frontend')
  .rpc('get_cosi', { _step_id: firstStep.id });

  if (error) {
    console.error('Error fetching COSI:', error);
    return;
  }

  return data;
};

const getProfile = async ()=>{
  const { data, error } = await supabase
  .rpc('get_enriched_user_v4')
  if (error) {
    console.error('Error fetching data:', error);
    return;
  }
  const profileData = data;
  profileData.fullName = [profileData.first_name, profileData.last_name].filter(Boolean).join(' ');
  return profileData;
}

const getEnrichedConnections = async (currentOrganization) => {
  const { data: orgIntegrations, error } = await supabase.schema('frontend')
          .rpc('get_enriched_connections_v2', { _organization_id: currentOrganization });

  const enrichedIntegrations = orgIntegrations.map(i => {
    const matchingIntegration = integrationsMeta.find(c => c.integration_id === i.integration_id);
    const enriched = {
      ...i,
      ...matchingIntegration,
      hidden: Boolean(matchingIntegration?.hidden),
    }
    if (!enriched.is_available) {
      enriched.status = "inactive";
    } else if (!enriched.connection_status && enriched.is_available) {
      enriched.status = "available";
    }  else if (enriched.connection_status === "active") {
      enriched.status = "active";
    } else if (enriched.connection_status === "configuring") {
      enriched.status = "setup";
    } else if (enriched.connection_status === "new") {
      enriched.status = "available";
    } else {
      enriched.status = "inactive";
      enriched.is_available = false;
    }
    return enriched;
  });
  
  return enrichedIntegrations.filter(i => !!i.name && !i.hidden);
};

const getEnrichedConnection = async (currentOrganization, integration_id) => {
  const { data: connection, error } = await supabase.schema('frontend')
          .rpc('get_enriched_connection_v2', { _organization_id: currentOrganization, _integration_id: integration_id });
  if (!connection) {
    return null;
  }
  const integrationMeta = integrationsMeta.find(i => i.integration_id === connection.integration_id);
  const enriched = {
    ...integrationMeta,
    ...connection,
  }
  if (enriched.connection_status === "active") {
    enriched.status = "active";
  } else if (enriched.connection_status === "configuring") {
    enriched.status = "setup";
  } else if (enriched.connection_status === "new") {
    enriched.status = "available";
  } else {
    enriched.status = "inactive";
    enriched.is_available = false;
  }
  return enriched;
};

const getEmbeddedAnalyticsUrl = async(organization_id, dashboard_id) => {
  const { data, error } = await supabase
    .rpc('generate_analytics_embed_url', { _organization_id: organization_id, _dashboard_id: dashboard_id});

  if (error) {
    console.error('Error getting embeded url:', error);
    return;
  }

  return data;
}

export {
  fetchSteps,
  fetchCOSI,
  getProfile,
  getEnrichedConnections,
  getEnrichedConnection,
  justFetchSteps,
  getConnectionInfo,
  getOrganizationProducts,
  getOrganizationConsumerProducts,
  getEmbeddedAnalyticsUrl,
  acceptTOSQuery,
  showUserTOS,
  getSubscription,
  updateDefaultOrg,
  getAvailableEmbeds,
  getPreSignedS3Url,
  getFileUploadHistory
};
